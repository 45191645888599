export { matchers } from './client-matchers.js';

export const components = [
	() => import("../../src/routes/__layout.svelte"),
	() => import("../runtime/components/error.svelte"),
	() => import("../../src/routes/patterns/__layout.svelte"),
	() => import("../../src/routes/index.svelte"),
	() => import("../../src/routes/patterns/strategy.svx")
];

export const dictionary = {
	"": [[0, 3], [1]],
	"patterns/strategy": [[0, 2, 4], [1]]
};